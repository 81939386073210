import React from 'react';
import PrivateStackShareCta from '../../../shared/library/private-stackshare-cta/index.js';
import {PHONE} from '../../style/breakpoints.js';

const customStyling = {
  height: 'auto',
  padding: '15px 20px 15px 15px',
  margin: '35px 0',
  [PHONE]: {
    padding: '15px 10px',
    margin: '0px 10px 20px'
  }
};

const StackFileBanner = () => {
  return (
    <PrivateStackShareCta
      title="Learn about our GitHub App that auto-creates tech stack docs (YML and Markdown files) that list out the full tech stack of a repo, without any manual work!"
      pageName="userProfilePage"
      customStyle={customStyling}
    />
  );
};

export default StackFileBanner;
